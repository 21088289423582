import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// Components
import Container from "../components/container"
import Logo from "../images/seelay-art.inline.svg"

// Styles
import "./footer.scss"

const Footer = ({ siteTitle }) => (
  <Container id="footer">
    <footer className="footer">
      <Link to="/">
        <Logo />
      </Link>
      <div className="footer__notes">
        <span className="footer__item">Copyright © 2022{siteTitle}</span>
        <a target="blank" href="https://www.seelay.in">
          <span id="seelay-footer">SEELAY</span>
        </a>
        <Link to="/legal" className="footer__item">
          Legal
        </Link>
        <Link to="/privacy" className="footer__item">
          Data protection
        </Link>
      </div>
    </footer>
  </Container>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: "",
}

export default Footer
